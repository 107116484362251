import * as React from "react";
import { Admin, Resource, ListGuesser } from 'react-admin';
import jsonServerProvider from 'ra-strapi-rest';
import {PostCreate} from "./resources/posts";

const dataProvider = jsonServerProvider('http://localhost:1337');
const App = () => (
    <Admin dataProvider={dataProvider}>
        <Resource name="posts" list={ListGuesser} create={PostCreate}/>
    </Admin>
)


export default App;
